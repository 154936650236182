import { render, staticRenderFns } from "./sign.vue?vue&type=template&id=5539b928&scoped=true&"
import script from "./sign.vue?vue&type=script&lang=js&"
export * from "./sign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5539b928",
  null
  
)

export default component.exports