<template>
  <van-form @submit="onSubmit" style="zoom: 2">
    <van-cell title="所属机构" :value="form.orgName"/>
    <van-field v-model="form.name" name="name" input-align="right" label="姓名" placeholder="姓名" :rules="[{ required: true, message: '请填写姓名' }]"/>
    <van-field v-model="form.phone" name="phone" input-align="right" label="电话" placeholder="电话" :rules="[{ required: true, message: '请填写密码' }]"/>
    <van-field v-model="form.position" name="position" input-align="right" label="职位" placeholder="职位"/>
    <div style="margin: 16px;">
      <van-button round block type="info" native-type="submit">签到</van-button>
    </div>
  </van-form>
</template>

<script>
import {Form, Dialog, Button, Cell, Field, Popup} from 'vant';
import {formatDate, post} from "ys-admin";

export default {
  name: 'eventSign',
  components: {
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
  },
  data() {
    return {
      form: {
        checkId: '',
        expertId: '',
        phone: '',
        policyId: '',
        position: '',
        signLng: '',
        signLat: '',
        orgName: '',
        name: '',
        orgId: '',
        signAt: formatDate(new Date(),'yyyy-MM-dd hh:mm:ss'),
      },
    }
  },
  mounted() {
    this.form.checkId = this.parentID || parseInt(this.$router.currentRoute.params.id);
    this.form.orgName = this.$router.currentRoute.params.orgName
    this.getLocation();
  },
  methods: {
    getLocation() {
      let that = this
      window.navigator.geolocation.getCurrentPosition(
          function (position) {
            that.form.signLng = position.coords.longitude;
            that.form.signLat = position.coords.latitude;
          },
          function onError(error) {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                break;
              case error.POSITION_UNAVAILABLE:
                console.log("位置信息是不可用的");
                break;
              case error.TIMEOUT:
                console.log("请求您的地理位置超时");
                break;
              case error.UNKNOWN_ERROR:
                console.log("未知错误");
                break;
            }
          }
      );
    },
    onSubmit() {
      post('/check/member/Add', this.form).then(res => {
        if (res.code === 200) {
          Dialog.alert({
            title: '提示',
            message: '签到成功！',
          }).then(() => {
            window.open("about:blank","_self")
            window.close()
          }).catch()
        }
      })
    },
  }
}
</script>
<style scoped>

</style>
